import { AxiosResponse } from "axios";
import { put, call, throttle } from "redux-saga/effects";
import {
  errorRazorPayTransferOrder,
  failureRazorPayTransferOrder,
  PaymentActions,
  succesRazorPayTransferOrder,
  firstDataPaymentSuccess,
  firstDataPaymentFailure,
  stripePaymentTokenRetrievealSuccess,
  stripePaymentFailure,
  stripePaymentSuccess,
  offlinePaymentSuccess,
  offlinePaymentFailure,
  cnpDataPaymentFailure,
  cnpDataPaymentSuccess,
} from "./PaymentActions";
import {
  razorPayTransferOrderAPI,
  catpurePaymentAPI,
  firstDataTransferOrderAPI,
  stripeInitatePaymentAPI,
  offlinePaymentAPI,
  initiatetCNPPayemnetAPI,
} from "./PaymentAPI";
import {
  CNP_DATA_PAYMENT_REQUEST,
  FIRST_DATA_PAYMENT_REQUEST,
  OFFLINE_PAYMENT_REQUEST,
  RAZOR_PAY_TRANSFER_ORDER,
  STRIPE_CAPTURE_PAYMENT,
  STRIPE_PAYMENT_REQUEST,
} from "./PaymentConstants";
import {
  CNPDataOrder,
  FirstDataOrder,
  RazorPayTransferOrder,
  RazorPayTransferredOrder,
  StripeCapturePaymentPayload,
  StripeInitiatePaymentPayload,
} from "./PaymentModels";
import { decryptJson } from "common/utils/react-ec-utils";

function* razorPayTransferOrderSaga(action: PaymentActions) {
  try {
    const response: AxiosResponse = yield call(
      razorPayTransferOrderAPI,
      action.payload as RazorPayTransferOrder
    );
    const decryptdata=decryptJson(response?.data?.encryptedText)
    if (response.status === 200) {
      // console.log(response.data);
      yield put(
        succesRazorPayTransferOrder(decryptdata as RazorPayTransferredOrder)
      );
    } else {
      yield put(
        failureRazorPayTransferOrder({
          message: decryptdata.message,
        })
      );
    }
  } catch (err) {
    console.log(err);
    yield put(errorRazorPayTransferOrder("Please Try Again"));
  }
}

function* firstDataPaymentSaga(action: PaymentActions) {
  try {
    const response: AxiosResponse = yield call(
      firstDataTransferOrderAPI,
      action.payload as FirstDataOrder
    );

    const decryptedData=decryptJson(response?.data?.encryptedText)
    if (response.status === 200 && decryptedData.paymentStatus === "18") {
      let reqBody = action.payload;
      reqBody.token = decryptedData.paymentTransactionTokenId;
      const paymentResponse: AxiosResponse = yield call(
        catpurePaymentAPI,
        action.payload as any
      );
      const decryptdata=decryptJson(paymentResponse?.data?.encryptedText)
      if (
        paymentResponse.status === 200 &&
        decryptdata.paymentStatus === "19"
      ) {
        yield put(firstDataPaymentSuccess());
      } else {
        yield put(firstDataPaymentFailure());
      }
    } else {
      yield put(firstDataPaymentFailure());
    }
  } catch (err) {
    console.log(err);
  }
}

function* stripeInitiatePaymentSaga(action: PaymentActions) {
  try {
    const paymentResponse: AxiosResponse = yield call(
      stripeInitatePaymentAPI,
      action.payload as StripeInitiatePaymentPayload
    );
    if (
      paymentResponse.status === 200 &&
      paymentResponse.data.paymentStatus === "18"
    ) {
      yield put(
        stripePaymentTokenRetrievealSuccess(
          paymentResponse.data.paymentTransactionId
        )
      );
    } else {
      yield put(stripePaymentFailure());
    }
  } catch (err) {
    console.log(err);
  }
}

function* offlinePaymentSaga(action: PaymentActions) {
  try {
    const paymentResponse: AxiosResponse = yield call(
      offlinePaymentAPI,
      action.payload as any
    );
     const decryptedData=decryptJson(paymentResponse?.data?.encryptedText);
    if (paymentResponse.status === 200) {
      yield put(offlinePaymentSuccess(decryptedData));
    } else {
      yield put(offlinePaymentFailure("Offline Payment Failed"));
    }
  } catch (err) {
    console.log(err);
  }
}

function* stripeCapturePaymentSaga(action: PaymentActions) {
  try {
    const paymentResponse: AxiosResponse = yield call(
      catpurePaymentAPI,
      action.payload as StripeCapturePaymentPayload
    );
    if (
      paymentResponse.status === 200 &&
      paymentResponse.data.paymentStatus === "19"
    ) {
      yield put(stripePaymentSuccess());
    } else {
      yield put(stripePaymentFailure());
    }
  } catch (err) {
    console.log(err);
  }
}

function* cnpDataPaymentSaga(action: PaymentActions) {

  let initiatePaymentRequest = action.payload as CNPDataOrder;

  try {
    const initiatePaymentResponse: AxiosResponse = yield call(initiatetCNPPayemnetAPI,initiatePaymentRequest,);

  if(initiatePaymentResponse.status === 200){
    const decryptedData=decryptJson(initiatePaymentResponse?.data?.encryptedText)
    if (initiatePaymentResponse.status === 200 && decryptedData.paymentStatus === "19") {
      yield put(cnpDataPaymentSuccess());
      if ( initiatePaymentRequest?.sagaCallBack != null &&typeof initiatePaymentRequest?.sagaCallBack === 'function') {
        initiatePaymentRequest.sagaCallBack(true);
      }
    } else {
      yield put(cnpDataPaymentFailure(decryptedData.message));
      if (
        initiatePaymentRequest?.sagaCallBack != null &&
        typeof initiatePaymentRequest?.sagaCallBack === 'function'
      ) {
        initiatePaymentRequest.sagaCallBack(false);
      }
    }
  }
  else {
    yield put(cnpDataPaymentFailure(initiatePaymentResponse.data));
    // console.log({initiatePaymentResponse})
    if (
      initiatePaymentRequest?.sagaCallBack != null &&
      typeof initiatePaymentRequest?.sagaCallBack === 'function'
    ) {
      initiatePaymentRequest.sagaCallBack(false);
    }
  }
  } catch (err) {
    console.log({err});
    yield put(cnpDataPaymentFailure(err.message));
    if (
      initiatePaymentRequest?.sagaCallBack != null &&
      typeof initiatePaymentRequest?.sagaCallBack === 'function'
    ) {
      initiatePaymentRequest.sagaCallBack(false);
    }
  }
}

export default function* paymentSaga() {
  yield throttle(3000, RAZOR_PAY_TRANSFER_ORDER, razorPayTransferOrderSaga);
  yield throttle(3000, FIRST_DATA_PAYMENT_REQUEST, firstDataPaymentSaga);
  yield throttle(3000, STRIPE_PAYMENT_REQUEST, stripeInitiatePaymentSaga);
  yield throttle(3000, STRIPE_CAPTURE_PAYMENT, stripeCapturePaymentSaga);
  yield throttle(3000, OFFLINE_PAYMENT_REQUEST, offlinePaymentSaga);
  yield throttle(3000, CNP_DATA_PAYMENT_REQUEST, cnpDataPaymentSaga);
}
