import { AxiosResponse } from "axios";
import { put, call, takeLatest, throttle } from "redux-saga/effects";
import { MetaDataInfo, tinyURLRequest, MetaResult } from "./tinyURLModels";
import {
  tinyURLActions,
  successgetActualURL,
  failgetActualURL,
} from "./tinyURLActions";
import { GET_TINYURL_REQUEST } from "./tinyURLConstants";
import { getActualURLAPI } from "./tinyURLAPI";

import { decryptJson } from "common/utils/react-ec-utils";

function* getActualURL(action: tinyURLActions) {
  try {
    const response: AxiosResponse = yield call(
      getActualURLAPI,
      action.payload as tinyURLRequest
    );
    const decryptdata=decryptJson(response?.data?.encryptedText)
    if (response.status === 200) {
      const result = decryptdata as MetaResult;
      console.log(result);
      if (result.metaDataInfo.responseCode == "SUCCESS")
        yield put(successgetActualURL(decryptdata as MetaResult));
      else yield put(failgetActualURL());
    } else {
      yield put(failgetActualURL());
    }
  } catch (err) {
    console.log(err);
    yield put(failgetActualURL());
  }
}
export default function* tinyURLSaga() {
  yield throttle(5000, GET_TINYURL_REQUEST, getActualURL);
}
