import { all, fork } from "redux-saga/effects";

// Imports: Redux Sagas
import checkInSaga from "common/features/checkIn/checkInSaga";
import authSaga from "common/features/auth/AuthSaga";
import restaurantSaga from "common/features/restaurant/restauranSaga";
import cartSaga from "./features/cart/cartSaga";
import paymentSaga from "./features/payment/PaymentSaga";
import OrderSaga from "./features/order/orderSaga";
import userSaga from "./features/user/userSaga";
import supportSaga from "./features/support/SupportSaga";
import offerSaga from "./features/offers/offferSaga";
import deliverySaga from "./features/delivery/DeliverySaga";
import tinyURLSaga from "./features/tinyURL/tinyURLSaga";
import schedulerSaga from "./features/scheduler/SchedulerSaga";
// Redux Saga: Root Saga
export default function* rootSaga() {
  yield all([
    fork(checkInSaga),
    fork(authSaga),
    fork(restaurantSaga),
    fork(cartSaga),
    fork(paymentSaga),
    fork(OrderSaga),
    fork(userSaga),
    fork(supportSaga),
    fork(offerSaga),
    fork(deliverySaga),
    fork(tinyURLSaga),
    fork(schedulerSaga),
  ]);
}
