// Get Restaurants
export const RESTAURANTS_REQUEST = "RESTAURANTS_REQUEST";
export const RESTAURANTS_SUCCESS = "RESTAURANTS_SUCCESS";
export const RESTAURANTS_FAILURE = "RESTAURANTS_FAILURE";

//Store
export const SERVING_RESTAURANTS_REQUEST = "SERVING_RESTAURANTS_REQUEST";
export const SERVING_RESTAURANTS_SUCCESS = "SERVING_RESTAURANTS_SUCCESS";
export const SERVING_RESTAURANTS_FAILURE = "SERVING_RESTAURANTS_FAILURE";
export const STORE_RESTAURANT_SERVING_LOCATIONS =
  "STORE_RESTAURANT_SERVING_LOCATIONS";

// Save Current Location
export const STORE_SELECTED_LOCATION = "STORE_SELECTED_LOCATION";
export const CLEAR_SELECTED_LOCATION = "CLEAR_SELECTED_LOCATION";

// Get Restaurant Details
export const RESTAURANT_DETAIL_REQUEST = "RESTAURANT_DETAIL_REQUEST";
export const RESTAURANT_DETAIL_SUCCESS = "RESTAURANT_DETAIL_SUCCESS";
export const RESTAURANT_DETAIL_FAILURE = "RESTAURANT_DETAIL_FAILURE";

// Get Restaurant by location Id
export const RESTAURANT_DETAIL_LOCATION_ID_REQUEST =
  "RESTAURANT_DETAIL_LOCATION_ID_REQUEST";
export const RESTAURANT_DETAIL_LOCATION_ID_SUCCESS =
  "RESTAURANT_DETAIL_LOCATION_ID_SUCCESS";
export const RESTAURANT_DETAIL_LOCATION_ID_CLEAR =
  "RESTAURANT_DETAIL_LOCATION_ID_CLEAR";

// Get Active Order Restaurants
export const ACTIVE_ORDER_RESTAURANT_REQUEST =
  "ACTIVE_ORDER_RESTAURANT_REQUEST";
export const ACTIVE_ORDER_RESTAURANT_SUCCESS =
  "ACTIVE_ORDER_RESTAURANT_SUCCESS";
export const ACTIVE_ORDER_RESTAURANT_CLEAR = "ACTIVE_ORDER_RESTAURANT_CLEAR";

// Get Restaurant Menu Details
export const RESTAURANT_MENU_DETAIL_REQUEST_BY_DATE = "RESTAURANT_MENU_DETAIL_REQUEST_BY_DATE";

export const RESTAURANT_MENU_DETAIL_REQUEST = "RESTAURANT_MENU_DETAIL_REQUEST";
export const RESTAURANT_MENU_DETAIL_SUCCESS = "RESTAURANT_MENU_DETAIL_SUCCESS";
export const RESTAURANT_MENU_DETAIL_FAILURE = "RESTAURANT_MENU_DETAIL_FAILURE";
export const CLEAR_RESTAURANT_DETAIL = "CLEAR_RESTAURANT_DETAIL";
export const REFRESH_MENU_DETAIL = "REFRESH_MENU_DETAIL";

//Feedback form
export const FEEDBACK_DETAIL_REQUEST = "FEEDBACK_DETAIL_REQUEST";
export const FEEDBACK_DETAIL_SUCCESS = "FEEDBACK_DETAIL_SUCCESS";
export const FEEDBACK_DETAIL_FAILURE = "FEEDBACK_DETAIL_FAILURE";
//Submit Feedback form
export const SUBMIT_FEEDBACK_DETAIL_REQUEST = "SUBMIT_FEEDBACK_DETAIL_REQUEST";
export const SUBMIT_FEEDBACK_DETAIL_SUCCESS = "SUBMIT_FEEDBACK_DETAIL_SUCCESS";
export const SUBMIT_FEEDBACK_DETAIL_FAILURE = "SUBMIT_FEEDBACK_DETAIL_FAILURE";
export const CLEAR_FEEDBACK_DATA = "CLEAR_FEEDBACK_DATA";
// store menu item for customisation
export const STORE_MENU_ITEM = "STORE_MENU_ITEM";

// Master HomePage Details
export const GET_MASTER_HOME_PAGE_DETAILS = "GET_MASTER_HOME_PAGE_DETAILS";
export const GET_MASTER_HOME_PAGE_DETAILS_SUCCESS =
  "GET_MASTER_HOME_PAGE_DETAILS_SUCCESS";
export const GET_MASTER_HOME_PAGE_DETAILS_ERROR =
  "GET_MASTER_HOME_PAGE_DETAILS_ERROR";

// Restaurant Table Details
export const GET_RESTAURANT_TABLE_DETAILS = "GET_RESTAURANT_TABLE_DETAILS";
export const GET_RESTAURANT_TABLE_DETAILS_SUCCESS =
  "GET_RESTAURANT_TABLE_DETAILS_SUCCESS";
export const GET_RESTAURANT_TABLE_DETAILS_FAILED =
  "GET_RESTAURANT_TABLE_DETAILS_FAILED";

  export const RESTAURANT_ORDER_TYPE_SWITCH_SUCCESS = 'RESTAURANT_ORDER_TYPE_SWITCH_SUCCESS';


  export const UPDATE_CD_GUEST_COUNT = "UPDATE_CD_GUEST_COUNT";

export const UPDATE_RESTAURANT_STATUS = "UPDATE_RESTAURANT_STATUS";

export const UPDATE_CURRENT_ACTIVE_MENU = "UPDATE_CURRENT_ACTIVE_MENU";