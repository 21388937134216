import { createStore, applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./rootReducer";
import rootSaga from "./rootSaga";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// // Redux Dev Tools
// declare global {
//   interface Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//   }
// }
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let outletId = "default"; 

const persistConfig = {
  key: `outlet_${outletId}`, // Placeholder, updated later
  storage,
  whitelist: [],
  blacklist: [],
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Saga Middle Init
const sagaMiddleware = createSagaMiddleware();

const Store = createStore(
  persistedReducer,
  // applyMiddleware(sagaMiddleware)
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

// Store.subscribe(() => {
//   const state = Store.getState();
//   const newOutletId = state.restaurant?.currentRestaurantDetail?.id; // Retrieve dynamically
//   if (newOutletId && newOutletId !== outletId) {
//     outletId = newOutletId;
//     const newKey = `outlet_${outletId}`;

//     // Purge the previous persisted state
//     Persistor.purge(); // This will clear the previous persisted data

//     // Update the persistConfig with the new key
//     persistConfig.key = newKey;

//     // Re-initialize the persisted reducer with the new key
//     const newPersistedReducer = persistReducer(persistConfig, rootReducer);

//     // Recreate the store with the updated persistedReducer
//     Store.replaceReducer(newPersistedReducer);

//     // Re-initialize the Persistor with the new store
//     Persistor.purge(); // Re-purge and rehydrate the new key
//     persistStore(Store); // Rehydrate the store with the new key
//     console.log(`Persist key updated to: ${newKey}`);
//   }
// });

const Persistor = persistStore(Store);

export { Store, Persistor  };
