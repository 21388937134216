import { AxiosResponse } from "axios";
import { put, call, takeLatest, select, delay } from "redux-saga/effects";
import { restaurantActions, successRestaurantDetails, failedRestaurantDetails,  successRestaurantMenuDetails } from "../restaurant/restaurantAction";
import { getRestaurantDetails, getRestaurantMenuDetails } from "../restaurant/restaurantAPI";
import { RestaurantDetailRequest, RestaurantMenuRequest, RestaurantMenuResponse } from "../restaurant/restaurantModels";
import { getEventDate, getEventSlug, getLocationSlug } from "../selectors";
import { PERFORM_TASK, SYNC_MENU_TASK } from "./SchedulerConstants";
import { decryptJson } from "common/utils/react-ec-utils";
import { getAvailableCatgorySubCategory } from "common/utils/menu-helper-utils";
import { storeCartRestaurantDetails } from "../cart/cartActions";
import { Store } from "common/configureStore";
import { UPDATE_CURRENT_ACTIVE_MENU } from "../restaurant/restaurantContants";

function* getRestaurantDetailsSaga(action: restaurantActions) {
    let payload = action.payload as RestaurantDetailRequest;
    const eventSlug: string | null = yield select(getEventSlug);
    const eventDate: boolean = yield select(getEventDate);
    const isEventOrder = Boolean(eventDate) || Boolean(eventSlug);

    const locationSlug: string | null = yield select(getLocationSlug);

    try {
        const response: AxiosResponse = yield call(getRestaurantDetails, locationSlug as string);
        if (response.status === 200) {
            const decryptdata = decryptJson(response.data.encryptedText)
            yield put(successRestaurantDetails({ detail: decryptdata, isEventOrder }));
            if (payload?.sagaCallBack != null && typeof payload?.sagaCallBack === 'function') {
                payload.sagaCallBack(true, decryptdata?.deliveryCutOffTimeExceeds, decryptdata?.pickupCutOffTimeExceeds, decryptdata);
            }

            const menuPaylaod = {
                type: payload?.menuType,
                locationId: payload?.restaurantId,
                customerId: null,
                searchText: null,
                visibleTo: "C",
            } as RestaurantMenuRequest
           // console.log({ menuPaylaod, payload });

            if (payload?.isMenuTriggerReq){
                yield delay(3000); 
                const menuResponse: AxiosResponse = yield call(getRestaurantMenuDetails, menuPaylaod as RestaurantMenuRequest);
                yield put({ type: UPDATE_CURRENT_ACTIVE_MENU, payload: menuPaylaod.type })

                if (menuResponse.status === 200) {
                    const responseData = decryptJson(menuResponse.data.encryptedText) as RestaurantMenuResponse;
                    const { categoryTypes, subCategoryTypes } = getAvailableCatgorySubCategory(responseData.menu);
                    yield put(storeCartRestaurantDetails(Store.getState().restaurant.currentRestaurantDetail, responseData.orderTax || [], responseData.defaultItemTax));
                    const rData = { ...responseData, categoryTypes, subCategoryTypes }
                    yield put(successRestaurantMenuDetails(rData));
                    yield put({ type: SYNC_MENU_TASK });

                }

            }



        } else {
            yield put(failedRestaurantDetails({ message: "Please Try Again", isEventOrder }));
            if (payload?.sagaCallBack != null && typeof payload?.sagaCallBack === 'function') {
                payload.sagaCallBack(false, 1, 1, '');
            }
        }
    } catch (err) {
        yield put(failedRestaurantDetails({ message: "Please Try Again", isEventOrder }));
        if (payload?.sagaCallBack != null && typeof payload?.sagaCallBack === 'function') {
            payload.sagaCallBack(false, 1, 1, '');
        }
    }
}


export default function* schedularSaga() {
    yield takeLatest(PERFORM_TASK, getRestaurantDetailsSaga);
}
