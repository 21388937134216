import API from "common/api";
import {
  AddFavRestaurants,
  LocationDetails,
  UserGivenDetail,
} from "./userModels";
import { encryptJson } from "common/utils/react-ec-utils";
var axios = require("axios");

export function getUserDetails() {
  const userID = localStorage.getItem("USER_ID");
  const encryptedData = encryptJson({id:userID});
  return (
    userID &&
    API({
      method: "post",
      url: `/customer/profile/v2`,
      data: {"data":encryptedData}
    })
  );
}

export function updateLocationDetails(data: LocationDetails) {
  const userID = localStorage.getItem("USER_ID");
  const encryptedData = encryptJson({customerLocation:data});
  return API({
    method: "put",
    url: `/customer/profile/v2`,
    data: {"data":encryptedData}
  });
}
export function deleteLocation(data: LocationDetails) {
  const userID = localStorage.getItem("USER_ID");
  const encryptedData = encryptJson({customerLocation:data});
  return API({
    method: "put",
    url: `/customer/profile/v2`,
    data: {"data":encryptedData}
  });
}

export function addFavRestaurant(data: AddFavRestaurants) {
  const userID = localStorage.getItem("USER_ID");
  const encryptedData = encryptJson({customerId:userID,favoriteMerchantLocationRequest:data});
  return API({
    method: "put",
    url: `/customer/profile/v2`,
    data:{'data':encryptedData}
  });
}
export function updateUserProfile() {
  return API({
    method: "put",
    url: "/customer/88e97221-feb5-4b35-a615-2f74a2132121/profile",
  });
}

export function updateEmail(email: string) {
  const userID = localStorage.getItem("USER_ID");
  const encryptedData = encryptJson({email:email});

  return API({
    method: "put",
    url: `/customer/profile/v2`,
    data: {'data':encryptedData},
  });
}

// For Promo Subscribed

export function promoConsent(promo: number) {
  const encryptedData = encryptJson({isPromoSubscribed:promo});

  return API({
    method: "put",
    url: `/customer/profile/v2`,
    data: encryptedData,
  });
}
