import API from "common/api";
import { tinyURLRequest } from "./tinyURLModels";
import { encryptJson } from "common/utils/react-ec-utils";
export function getActualURLAPI(details: tinyURLRequest) {
  const data = encryptJson(details)
  return API({
    method: "POST",
    url: "/customer/getActualURL/v2",
    data:{"data":data}
  });
}
