import React, { useState, Fragment, useCallback } from "react";
import { useSpring, useTransition, animated } from "react-spring";
import { ReactComponent as CancelIcon } from "common/assets/svg/cancel.svg";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { USER_ID } from "common/constants";
import VegDishIcon from "common/assets/images/dishPlaceholder.png";

import { RootState } from "common/rootReducer";

function DiscriptionPopup(props: any) {
  const [modalState, setModalState] = useState(false);
  const { isOpen, closePrompt } = props;
  const currentSelectedItem = useSelector(
    (state: RootState) => state.cart.currentMenuItem
  );

  const getMenuItemImageUrl = useCallback((imgId: string, ext: string) => {
    return process.env.REACT_APP_IMAGE_URL + "img" + "/" + imgId + "." + ext;
  }, []);

  const transitions = useTransition(isOpen, null, {
    from: { transform: "translate3d(0,-15px,0)" },
    enter: { transform: "translate3d(0,0px,0)" },
    leave: { transform: "translate3d(0,-15px,0)" },
    unique: true,
  });

  const opacityProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
  });

  // const { itemImage, itemType } = currentSelectedItem;

  return (
    <Fragment>
      {isOpen ? (
        <>
          <div id="modals-background"></div>
          <animated.div className="menu-item-ELWrappers" style={opacityProps}>
            <animated.div className="menu-item-ELContainer" style={props}>
              <div className="ItemHeader">
                {/* <span>Enable your Location</span> */}
                <CancelIcon onClick={closePrompt} height={16} width={16} />
              </div>
              <div className="content-container">
                <div id="image-container">
                  {currentSelectedItem &&
                  currentSelectedItem?.itemImage &&
                  currentSelectedItem?.itemType ? (
                    <img
                      src={getMenuItemImageUrl(
                        currentSelectedItem?.itemImage,
                        currentSelectedItem?.itemType?.split("/")[1]
                      )}
                      alt="dish"
                    />
                  ) : (
                    <img
                      className="dishPlaceholder"
                      src={VegDishIcon}
                      alt="dish"
                    />
                  )}
                </div>
                <div className="itemName">{currentSelectedItem?.itemName}</div>
                <div className="ingredient-container">
                  {currentSelectedItem &&
                    currentSelectedItem.ingredient?.map((ingredient, index) => {
                      return (
                        <span id="food-content-container" key={index}>
                          <span className="food-content-image-container">
                            <img
                              src={getMenuItemImageUrl(
                                ingredient.img,
                                ingredient.type.split("/")[1]
                              )}
                              alt="img"
                            />
                          </span>
                          {ingredient.name}
                        </span>
                      );
                    })}
                </div>
              </div>
              <div className="description-container">
                <span className="description-text">
                  {currentSelectedItem && currentSelectedItem.description}
                </span>
              </div>
            </animated.div>
          </animated.div>
        </>
      ) : null}
    </Fragment>
  );
}

export default DiscriptionPopup;
