import { useSelector } from "react-redux";
import { RootState } from "common/rootReducer";

// Custom hook: name starts with "use"
export const useIsEventOrders = () => {
  const eventSlug = useSelector((state: RootState) => state.cart.eventDate);
  const eventDate = useSelector((state: RootState) => state.cart.isEventDate);
  
  return Boolean(eventDate) || Boolean(eventSlug);
};


export const getEventSlug = (state: RootState) => state.cart.eventDate;
export const getEventDate = (state: RootState) => state.cart.isEventDate;

export const getLocationSlug = (state: RootState) => state.restaurant.currentRestaurantDetail?.locationSlug;